import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector, connect } from "react-redux";

import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


const SignIn = (props) => {
  const dispatch = useDispatch();

  const token = useSelector(({auth}) => auth.token);

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid">
            </div>
            <div className="gx-app-logo">
              {/* <img alt="example" src={require("assets/images/logo.png")}/> */}
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                rules={[{ required: true, message: 'The input your client Id!' }]} name="clientId">
                <Input placeholder="Client ID"/>
              </Form.Item>
              <Form.Item
                rules= {[{required: true, message: 'Please input your Password!'}]}  name="password">
                <Input type="password" placeholder="Password"/>
              </Form.Item>
              { props.error !== "" ?
                <div className="gx-text-danger">Please check client ID and password</div>:<div></div>
              }
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn"/>
                </Button>
              </Form.Item>
              <span
                className="gx-text-light gx-fs-sm">Please contact dart team to get your client ID and password!</span>
            </Form>
          </div>
          <InfoView/>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ common }) => {
  const { error } = common;
  return {
    error
  }
}

export default  connect(mapStateToProps, null)(SignIn);;
