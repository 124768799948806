import {
  INIT_URL,
  REPORT_SUMMARY,
  REPORT_DETAILED_SUMMARY,
} from "../../constants/ActionTypes";
import { v4 as uuidv4 } from 'uuid';

const INIT_STATE = {
  reportSummary: [],
  departmentSummary: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case REPORT_SUMMARY: {
      let reportDetails = [];
      let data = action.payload;
      data.forEach((element) => {
        let filteredList = reportDetails.filter(
          (el) => el.StoreName === element.StoreName
        );
        if (filteredList.length === 0) {
          var counterDetails = new Array({
            CounterNo: element.CounterNo,
            Total: element.Total,
            Discount: element.Discount,
            VAT: element.VAT,
            NetTotal: element.NetTotal,
            MaxRange: element.MaxRange,
            key : uuidv4()
          });

          var storeDetails = {
            StoreName: element.StoreName,
            Total: element.Total,
            Discount: element.Discount,
            VAT: element.VAT,
            NetTotal: element.NetTotal,
            MaxRange: element.MaxRange,
            CounterDetails: counterDetails,
            key : uuidv4()
          };

          reportDetails.push(storeDetails);
        } else {
            filteredList[0].Total += element.Total;
            filteredList[0].Discount += element.Discount;
            filteredList[0].VAT += element.VAT;
            filteredList[0].NetTotal += element.NetTotal;
            filteredList[0].MaxRange += element.MaxRange;
            filteredList[0].CounterDetails.push({
              CounterNo: element.CounterNo,
              Total: element.Total,
              Discount: element.Discount,
              VAT: element.VAT,
              NetTotal: element.NetTotal,
              MaxRange: element.MaxRange,
              key : uuidv4()
            });
        }
      });


      return {
        ...state,
        reportSummary: reportDetails,
      };
    }

    case REPORT_DETAILED_SUMMARY: {
      return {
        ...state,
        departmentSummary: action.payload,
      };
    }

    default:
      return state;
  }
};
