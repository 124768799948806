import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import PullToRefresh from "react-simple-pull-to-refresh";

const store = configureStore(/ provide initial state if any /);

const handleRefresh = () => {
  window.location.reload(false);
};


const NextApp = () => (
  <PullToRefresh onRefresh={handleRefresh}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
    ;
  </PullToRefresh>
);

export default NextApp;
