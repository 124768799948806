import React, {useState} from "react";
import {Button, Drawer, Radio} from "antd";
import {useDispatch, useSelector} from "react-redux";
import Auxiliary from "util/Auxiliary";
import CustomScrollbars from "util/CustomScrollbars";
import {onLayoutTypeChange, setThemeType} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  THEME_TYPE_DARK,
  THEME_TYPE_LITE,
} from "../constants/ThemeSetting";


const CustomizerSystem = () => {

  const [isCustomizerOpened, setIsCustomizerOpened] = useState(false);
  const dispatch = useDispatch();

  const themeType = useSelector(({settings}) => settings.themeType);
  const layoutType = useSelector(({settings}) => settings.layoutType);


  const toggleCustomizer = () => {
    setIsCustomizerOpened(!isCustomizerOpened);
  };

  const onThemeTypeChange = (e) => {
    dispatch(setThemeType(e.target.value));
  };


  const getCustomizerContent = () => {

    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "/css/dark_theme.css";
      link.className = 'style_dark_theme';
      document.body.appendChild(link);
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      const children = document.getElementsByClassName('style_dark_theme');
      if (children.length > 1) {
        for (let index = 0; index < children.length; index++) {
          if (index < children.length) {
            const child = children[index];
            child.parentNode.removeChild(child);
          }
        }
      }
    }

    return <CustomScrollbars className="gx-customizer">
      <div className="gx-customizer-item">
        <h6 className="gx-mb-3 gx-text-uppercase">Theme</h6>
        <Radio.Group value={themeType} onChange={onThemeTypeChange}>
          <Radio.Button value={THEME_TYPE_LITE}>Lite</Radio.Button>
          <Radio.Button value={THEME_TYPE_DARK}>Dark</Radio.Button>
        </Radio.Group>
      </div>

      <h6 className="gx-mb-3 gx-text-uppercase">Layout</h6>

      {getLayoutsTypes(layoutType)}
    </CustomScrollbars>
  };

  const handleLayoutTypes = (layoutType) => {
    dispatch(onLayoutTypeChange(layoutType));
  };

  const getLayoutsTypes = (layoutType) => {
    return <ul className="gx-layout-option gx-list-inline">
      <li>
        <span onClick={() => handleLayoutTypes(LAYOUT_TYPE_FRAMED)}
              className={`gx-pointer ${layoutType === LAYOUT_TYPE_FRAMED && 'active'}`}>
        <img src={require('assets/images/layouts/framed.png')} alt='framed'/>
        </span>
      </li>
      <li>
        <span onClick={() => handleLayoutTypes(LAYOUT_TYPE_FULL)}
              className={`gx-pointer ${layoutType === LAYOUT_TYPE_FULL && 'active'}`}>
        <img src={require('assets/images/layouts/full width.png')} alt='full width'/>
        </span>
      </li>
      <li>
        <span onClick={() => handleLayoutTypes(LAYOUT_TYPE_BOXED)}
              className={`gx-pointer ${layoutType === LAYOUT_TYPE_BOXED && 'active'}`}>
        <img src={require('assets/images/layouts/boxed.png')} alt='boxed'/>
        </span>
      </li>
    </ul>
  };



  return (
    <Auxiliary>
      <Drawer
        placement="right"
        closable={false}
        onClose={toggleCustomizer}
        visible={isCustomizerOpened}>
        {
          getCustomizerContent()
        }
      </Drawer>
      <div className="gx-customizer-option">
        <Button type="primary" onClick={toggleCustomizer}>
          <i className="icon icon-setting fxicon-hc-spin gx-d-block"/>
        </Button>
      </div>
    </Auxiliary>
  );
};

const Customizer = CustomizerSystem;


export default (Customizer);
